import React from "react";
import { Helmet } from "react-helmet";
import { DOMAIN } from "../../config";
import { LifeHomeCareStructuredData } from "./schemaData";

const Seoinfo = (props) => (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{props.titlePage}</title>
      <meta name="description" content={props.description} />
      <meta name="keywords" content={props.keywords} />
      <meta property="og:type" content={props.type} />
      <meta property="og:title" content={props.titlePage} />
      <meta property="og:url" content={`${DOMAIN}${props.url}/`} />
      <meta property="og:image" content={`${DOMAIN}${props.imageFacebook}`} />
      <meta property="og:description" content={props.description} />
      <meta property="og:site_name" content={props.siteName} />
      <meta
        name="twitter:title"
        content="Compassionate Care in the Comfort of Your Home | Life Home Care"
      />
      {props.canonical && (
        <link rel="canonical" href={`${DOMAIN}${props.url}`} />
      )}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@lifehomecare" />
      <meta name="twitter:creator" content="@lifehomecare" />
      <meta property="og:url" content="URL" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:site_name" content="Life Home Care" />
      <script type="application/ld+json">
        {JSON.stringify(LifeHomeCareStructuredData(props))}
      </script>
    </Helmet>
  );

export default Seoinfo;
