import React from "react";

import { ArrowIcon } from "./assets/ArrowIcon";
import transformToSlug from "../../utils";

import {
  ArrowContainer,
  Container,
  LinksContainer,
  Title,
  Subtitle,
  LinkWrapper,
} from "./TownshipIndex.styles";

const Township = (props) => {
  return (
    <Container>
      <Title>OUR HOME CARE SERVICE AREA</Title>
      <Subtitle>{props.subtitle}</Subtitle>
      <LinksContainer>
        {props.townships.map((township) => (
          <LinkWrapper
            key={township.attributes.Township}
            href={`/locations${props.countyUrl}/home-care-in-${transformToSlug(
              township.attributes.Township
            )}/`}
          >
            {township.attributes.Township}
            <ArrowContainer>
              <ArrowIcon />
            </ArrowContainer>
          </LinkWrapper>
        ))}
      </LinksContainer>
    </Container>
  );
};

export default Township;
