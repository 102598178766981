import React from "react";

import HeroDesktop from "./assets/hero-desktop.png";
import HeroMobile from "./assets/hero-mobile.png";
import PhoneIcon from "./assets/phone-icon.svg";

import {
  HeroContainer,
  HeroPicture,
  HeroPictureMobile,
  HeroTextDiv,
  InternalWrapper,
  Paragraph,
  Subtitle,
  Title,
  PhoneContainer,
  PhoneText,
  Text,
} from "./Hero.styles";
const Hero = (props) => {
  return (
    <HeroContainer>
      <HeroPicture>
        <source srcSet={HeroDesktop} type="image/avif" />
        <img src={HeroDesktop} alt="Smiling Life Homecare Nurse" />
      </HeroPicture>
      <HeroPictureMobile>
        <source srcSet={HeroMobile} type="image/avif" />
        <img src={HeroMobile} alt="Smiling Life Homecare Nurse" />
      </HeroPictureMobile>
      <InternalWrapper data-aos="fade">
        <HeroTextDiv>
          <Title>{props.title}</Title>
          <Subtitle>
            <Paragraph>
              Seeking reliable home care? Call us today at
              <PhoneContainer>
                <img src={PhoneIcon} alt="Phone Icon" />
              </PhoneContainer>
              <PhoneText>973-273-3441</PhoneText>
            </Paragraph>
            <Text>
              We’re here to address your needs and provide personalized care
              solutions. Feel free to reach out to us for any inquiries you may
              have.
            </Text>
          </Subtitle>
        </HeroTextDiv>
      </InternalWrapper>
    </HeroContainer>
  );
};

export default Hero;
