import { PATHS } from "../../config/urls";

export const LifeHomeCareStructuredData = (props) => ({
  "@context": "https://schema.org",
  "@type":
    props.url === PATHS.INDEX
      ? "WebSite, SiteNavigationElement, Organization"
      : "WebPage, BreadcrumbList, Organization",
  address: {
    "@type": "PostalAddress",
    addressLocality: "Livingston",
    postalCode: "07039",
    streetAddress: "70 W S Orange Ave #220",
    addressRegion: "NJ",
    addressCountry: "US",
  },
  aggregateRating: {
    "@type": "AggregateRating",
    ratingValue: "5",
    reviewCount: "81",
  },
  review: {
    "@type": "Review",
    reviewRating: {
      "@type": "Rating",
      ratingValue: "5",
    },
    author: {
      "@type": "Person",
      name: "Larry Rakitt",
    },
    datePublished: "2021-08-20",
    reviewBody:
      "From my first phone call to ask about getting a wheelchair to a mere 22 hours later for its delivery to my door, the service, concern,     and efficiency with this agency was outstanding.  If anyone is in need of     medical equipment or other services provided by an agency, contact     Life Home Care in Livingston.  You will be more than pleasantly surprised     with their services and, more importantly, their dedication to excellence.",
    name:
      "I can't say enough good things about Life Home Care - 5 stars is not sufficient!",
  },
  logo:
    "https://lh4.googleusercontent.com/-J5DMwDpIF4M/AAAAAAAAAAI/AAAAAAAAAAA/ZBLRZYtJBYc/s88-p-k-no-ns-nd/photo.jpg",
  image: "https://homecare.life/seo/seo_image.png",
  url: "https://homecare.life",
  name: "Life Home Care",
  telephone: "+1 973-273-3441",
  email: "michelle@homecare.life",
});
